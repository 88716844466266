import React from "react";

const MessageBox = ({ type, message }) => {
  const getAlertClass = () => {
    switch (type) {
      case "success":
        return "alert alert-success";
      case "error":
        return "alert alert-danger";
      default:
        return "alert alert-info"; // Default to a generic message style
    }
  };

  return (
    <div className={getAlertClass()} role="alert">
      {message}
    </div>
  );
};

export default MessageBox;
