import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Container, Row, Col } from "react-bootstrap";

function Home() {
  return (
    <div>
      <Header />
      <div
        className="coming-soon-page"
        style={{
          height: "100vh",
          backgroundColor: "#f8f9fa",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container className="text-center">
          <Row>
            <Col>
              <h1
                style={{
                  fontSize: "4rem",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Coming Soon!
              </h1>
              <p
                style={{
                  fontSize: "1.5rem",
                  color: "#6c757d",
                  marginBottom: "2rem",
                }}
              >
                We are working hard to launch something amazing. Stay tuned!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
